(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).foundation();

window.onload = function () {
  stickyFooter();
  $('.menu.vertical.accordion-menu[data-accordion-menu] .is-submenu-item.is-accordion-submenu-item > a.current_sub').parents().find('.is-active.is-accordion-submenu-parent > .submenu-toggle ').trigger('click');
};

function checkForDOMChange() {
  stickyFooter();
}

window.onresize = function () {
  stickyFooter();
};

function getCSS(element, property) {
  var elem = document.getElementsByTagName(element)[0];
  var css = null;

  if (elem.currentStyle) {
    css = elem.currentStyle[property];
  } else if (window.getComputedStyle) {
    css = document.defaultView.getComputedStyle(elem, null).getPropertyValue(property);
  }

  return css;
}

function stickyFooter() {
  if (document.getElementsByTagName("footer")[0].getAttribute("style") != null) {
    document.getElementsByTagName("footer")[0].removeAttribute("style");
  }

  if (window.innerHeight != document.body.offsetHeight) {
    var offset = window.innerHeight - document.body.offsetHeight;
    var current = getCSS("footer", "margin-top");

    if (isNaN(current) == true) {
      document.getElementsByTagName("footer")[0].setAttribute("style", "margin-top:0px;");
      current = 0;
    } else {
      current = parseInt(current);
    }

    if (current + offset > parseInt(getCSS("footer", "margin-top"))) {
      document.getElementsByTagName("footer")[0].setAttribute("style", "margin-top:" + (current + offset) + "px;");
    }
  }
}

},{}]},{},[1]);
